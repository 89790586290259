import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  id: "",
  registered: [],
  name: "",
  country: "",
  city: "",
  legal_type: "",
  status: "",
  incorporation_year: null,
  industry: "",
  jurisdiction: "",
  num_employees: null,
  annual_turnover: null,
  monthly_txns: null ,
  taxable_income: null ,
  taxable_expenses: null ,
  visa_city: null,
  branches: false,
  establishment: null,
  tax_group: false,
  goods_vendor: false,
  service_vendor: false,
  cross_border_txns: false,
  // is_default: false, // causing issues like altering
  profile_complete: false
};

const accountSettingSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {

    setProfileEntity: (state, action) => {
      // console.log(action.payload)
      return {...action.payload}
  },
  setUpdateProfileEntity: (state, action) => {
      return {...state, ...action.payload}
  },

  }
});

export const {
  setProfileEntity,
  setUpdateProfileEntity
} = accountSettingSlice.actions;

export default accountSettingSlice.reducer;

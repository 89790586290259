import React, { useState, useEffect, useContext, useRef } from "react";
import { SERVICES } from "../../utils/ConstantSettings";

//languages:

import { useTranslation } from "react-i18next"; 

import Sidebar from "../../components/sidebar/Sidebar";
import styles from "./ChatBot.module.scss";
import "../../assets/scss/dropdown.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dropdown from "react-bootstrap/Dropdown";
// import uae_logo from '../../assets/images/uae.png'
// import bahrain_logo from '../../assets/images/bahrain.png'
// import ksa_logo from '../../assets/images/saudi-arabia.png'
// import jordan_logo from '../../assets/images/jordan.png'
// import oman_logo from '../../assets/images/oman.png'
// import qatar_logo from '../../assets/images/qatar.png'
// import globe_logo from '../../assets/images/globe.png'
// import egypt_logo from '../../assets/images/egypt.png'

// import logo from "../../assets/images/Logo.png";
import { AuthContext } from "../../utils/AuthContext";
import './main.css'
import "./SubscriptionPlanModal.css"
import InvoiceScreen from "../Invoice/invoice";
import DataResultsOnboarding from "../DataResultsOnboarding";
import GraphOnboardingResult from "../GraphResultOnboarding";
import Analyze from "../Analysis";
import {
  CommentIcon,
  UploadFileIcon,
  TablerIcon,
  PlusIconDark,
  SettingIcon,
  LogoutDropdownIcon,
  FeedbackIcon,
  CrossIcon,
  AdviseModuleIcon,
  ProcessModuleIcon,
  AnalyseModuleIcon,
  EncircledCrossExit,
} from "../../assets/SVG/svg";
// import simplaLogo from "../../assets/images/simpla.png";
// import ProfileImage from "../../assets/images/banner.png";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getprofile,
  getchatroom,
  getAllBatches,
  getAnalyticsHistory,
  updateuserInfo,
} from "../../services/api";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import ButtonRounded from "../../components/common/Button/ButtonRounded";
import FeedbackModal from "../../components/FeedbackModal";
import JoyrideComponent from "../../components/common/joyRide";
import { ProfileSetting } from "../ProfileSetting/ProfileSetting";
import { useTour } from "../../components/common/joyRide/TourContext";
import { Spinner } from "react-bootstrap";
import { SetAxiosConfig } from "../../utils/axiosConfig";
import { all_countries } from "../../utils/SetupDropdown";
import { Box, Checkbox, Typography, Button as MuiButton } from "@mui/material";
import axios from "axios";
import { setRefetchValue } from "../../ReduxSlices/HistoryRefetchSlice";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


//redux
import { useDispatch, useSelector } from "react-redux";
import { setDisplaySidebar } from "../../ReduxSlices/SidebarSlice";
import { lazy, Suspense } from "react";
//import MenuIcon from '@mui/icons-material/Menu';
import { disableAllChatMode } from "../../ReduxSlices/ChatModeSlice";
import { setApiCount } from "../../ReduxSlices/ApiCountSlice";
import { useTracking } from "react-tracking";
import { useStopwatch } from "react-timer-hook";

import { setProfileCompleteModalState } from "../../ReduxSlices/ProfileCompletionSlice";
import { setprofileSettingModalOpen, setprofileSettingModalTab } from "../../ReduxSlices/ProfileSettingModal";
import { fetchApiCount, fetchSimplaEnterpriseMailStatus, getProfileCompletionStatus, getSignOffCredits, questionaireStatus } from "../../services/ModuleApi";
import { setUpdateProfileEntity } from '../../ReduxSlices/AccountSettingSlice';
import { localStorageValueTester } from "../../utils/UtilityFunctions";

// import {AppBar, Toolbar, IconButton} from "@mui/material"
// import MenuIcon from '@mui/icons-material/Menu'
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// nav bar MUI
// Lazy import MUI components
const AppBar = lazy(() => import('@mui/material/AppBar'));
const Toolbar = lazy(() => import('@mui/material/Toolbar'));
const IconButton = lazy(() => import('@mui/material/IconButton'));
const MenuIcon = lazy(() => import('@mui/icons-material/Menu'));

// import { AppBar, Toolbar, IconButton} from "@mui/material";

const ChatBotLayout = ({children}) => {

  const { trackEvent } = useTracking({ component: 'Chatbot' });

  const {t} = useTranslation()

  const encodedImg = `${useSelector((state)=>state.profileImage)}`
  const userImgAws = atob(`${encodedImg.substring(0,encodedImg.length-6)}`)

  const languages = [
    {code: "en", name: "English",nameInEng: "English", countryCode: 'gb' /* great britain */ },
    {code: "ar", name: "العربية",nameInEng: "Arabic", countryCode: 'sa' /* saudi arabia */  },
]



const currentPlan = useSelector((state)=>state.currentPlan.name)


function currentPlanStyleClass(plan){

  if (localStorage.getItem('i18nextLng') === "ar" ){
      if (plan === "free" || plan === "questioner"){
        return "trial-plan-ar"
      }
      else if (plan.includes("Starter")){
        return "starter-plan-ar"
      }
      else {
        return "trial-plan-ar"
      }    
  }
  else{
    if (plan === "free" || plan === "questioner"){
      return "trial-plan-en"
    }
    else if (plan.includes("Starter")){
      return "starter-plan-en"
    }
    else {
      return "trial-plan-en"
    }   
  }

}

const [hideFeedbackBtn, setHideFeedbackBtn] = useState(window.innerWidth <= 750 ? true : false)

useEffect(() => {
    // console.log("hideFeedbackBtn ", hideFeedbackBtn)
    const handleResize = () => {
        //console.log(window.innerWidth)
        if (window.innerWidth <= 750){
            setHideFeedbackBtn(true)
        }
        else {
            setHideFeedbackBtn(false)
        }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



//   function handleFeedbackBtnVisibility(){
//     if (tourState.isTourActive === true &&  tourState.currentStep === 11){
//         return false
//     }
//     else return hideFeedbackBtn
//   }

const SuspenseFallback = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // width: '100vw',
      height: '100vh',
      background: '#faf5e1',
      paddingBottom: '10%',
    }}
  >
    <Spinner />
  </div>
);

function discoverSimplaSendEmail(sendOption="email"){

  setShowEnterpriseModal(true)

  if (localStorage.getItem("simpla_enterprise_email_sent") == "false"){
      axios.post(`${SERVICES.apiBaseUrl}/api_usage/createPremiumRequest`, {notification_method: `${sendOption}`}, 
        {headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=UTF-8',

        }})
        .then((res)=>{
          let status;
          try {
            status = JSON.parse(res.data?.object)?.status
            localStorage.setItem("simpla_enterprise_email_sent", status )
          }
          catch(error){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }

          if (res.data?.message === "Success" || res.data?.message === "success"){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }
        })
        .catch((error)=>{
          //console.log(error?.response?.data?.error);
          trackEvent({
            event_type: "error", 
            event_name:"Failed to send get-in-touch email request", 
            event_data:{
              error: `${error}`
            }
          })
          if (error?.response?.data?.error === "User has already requested for premium" || error?.response?.status === 400){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }
        })
  }

  
}

const profileStatus = useSelector((state)=>state.profileCompletion?.profileStatus)
const profileStatusObj = useSelector((state)=>state.profileCompletion)

const AccountSetting = useSelector((state) => state.AccountSetting)




    // profile complete modal
    const [showProfileCompleteModal, setShowProfileCompleteModal] = useState(false)
    const handleShowProfileCompleteModal = ()=> setShowProfileCompleteModal(true)
    const handleCloseProfileCompleteModal = ()=> {
      setShowProfileCompleteModal(false);
      dispatch(setProfileCompleteModalState(false))
      dispatch(disableAllChatMode());
      navigate("/")
    }


    useEffect(()=>{
      setShowProfileCompleteModal(profileStatusObj.modalOpen)
    }, [profileStatusObj.modalOpen])

    // mui drop down menu
    const [langAnchorEl, setLangAnchorEl] = useState()
    const [openLangMenu, setOpenLangMenu] = useState(false);
    const handleClick = (event) => {
    setOpenLangMenu(true);
    setLangAnchorEl(event.currentTarget);
    };
    const handleCloseLangMenu = () => {
    setLangAnchorEl(null);
    setOpenLangMenu(false)
    };

    // Retrieve values from localStorage
    const firstname = localStorage.getItem('firstname') || '';
    const lastname = localStorage.getItem('lastname') || '';
  
    // Get the first character from both firstname and lastname
    const firstnameAcronym = firstname.charAt(0);
    const lastnameAcronym = lastname.charAt(0);

    // function to set bg color for profile

    function setBGColor(){
      if (window.location.href.includes('invoice')){
        return "#FFBE0A"
      }
      else if (window.location.href.includes('analyse')){
        return "#78CD32"
      }
      else return "#9696FA"
    }

    // want to use more of the module modal pop up:
    const [showModal, setShowModal] = useState(false);
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    // My plan modal pop up:
    const [showMyPlanModal, setMyPlanShowModal] = useState(false);
    const handleMyPlanModalShow = () => setMyPlanShowModal(true);
    const handleMyPlanModalClose = () => setMyPlanShowModal(false);

    // Enterprise modal pop up:
    const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);
    const handleShowEnterpriseModal = () => {
      setShowEnterpriseModal(true)
    };
    const handleCloseEnterpriseModal = () => setShowEnterpriseModal(false);

    // Simpla USE modal pop up:
    const [showUseModal, setShowUseModal] = useState(false);
    const handleShowUseModal = () => setShowUseModal(true);
    const handleCloseUseModal = () => setShowUseModal(false);


  // redux function to open and close sidebar
  const displayBar = useSelector((state)=>state.displaySidebar.value)
  const questioner_filled = useSelector((state)=>state.questioner_filled)
  const currentPlanObj = useSelector((state)=>state.currentPlan)


  const dispatch = useDispatch()
  
  function handleDisplay(){
    if (displayBar === 'none'){
      //setDisplayBar('block')
      dispatch(setDisplaySidebar('block'))
    }
    else 
    {
      dispatch(setDisplaySidebar('none'))
    } 
  }

  // console.log("lang = " + localStorage.getItem('i18nextLng'))


  SetAxiosConfig();
  const { startTour, endTour, tourState } = useTour();
  const [show, setShow] = useState(false);
  // used to open profile setting modal
  const [showAccSettings, setShowAccSettings] = useState(false);
  const profileSettingModal = useSelector((state)=>state.profileSettingModal)
  const [showOnboarding, setShowOnboarding] = useState(false);
  const handleCloseOnboarding = () => {
    navigate("/");
    startTour();
    setShowOnboarding(false);
    if(localStorage.getItem('show-disclaimer') == true || localStorage.getItem('show-disclaimer') == "true" || !localStorage.getItem('show-disclaimer') || localStorage.getItem("show-disclaimer") == undefined || localStorage.getItem("show-disclaimer") == null ){
      setOpen(true);
    }
  };

//new

useEffect(()=>{
  setShowAccSettings(profileSettingModal.open)
},[profileSettingModal.open, profileSettingModal.tab])
 
  const handleSkipOnboarding = () => {
    setShowOnboarding(false);
    endTour();
  
    
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowAccSetting = () => setShowAccSettings(true);
  const handleCloseAccSetting = () => {
    setShowAccSettings(false);
    dispatch(setprofileSettingModalOpen(false))
    dispatch(setprofileSettingModalTab(""))
  }


  const [open,setOpen]=useState(false);
  const [toggleActive,setToggleActive]=useState(false);
  const navigate = useNavigate();
  const [profileid, setProfileid] = useState(localStorage.getItem("profileid"));
  const [feedback, setFeedback] = useState(false);
  const [filter, setFilter] = useState("All");
  const [isChecked,setIsChecked]=useState(false);
  const [search, setSearch] = useState("");
  const filterSetter = (val) => {
    setFilter(val);
  };


//   useEffect(()=>{
//     console.log("tourState: ")
//     console.log(tourState)
//   }, [tourState])

  const searchSetter = (val) => {
    setSearch(val);
  };
      const uselocation = useLocation();

  const navigateToSurvey = () => {
    if (  questioner_filled == false ){
    
      // Access current pathname, search, and hash
      const { pathname, hash } = uselocation;

      // Full current URL
      //let currentURL = "/"
    
      if(!`${pathname}${hash}`.includes("survey")){
              //currentURL = `${pathname}${hash}`
              localStorage.setItem("currentURL", `${pathname}${hash}`)
      }
      navigate("/survey")
    }
    else {
      setShowModal(false)
      setShowUseModal(false)
      setMyPlanShowModal(false)
      discoverSimplaSendEmail()
    }
  };

  const [themeclass, setThemeclass] = useState(
    window.location.pathname.includes("invoice")
      ? "bg-yellow-wrapper"
      : "bg-blue-wrapper"
  );

  const [defaultTab, setDefaultTab] = useState(
    window.location.pathname.includes("invoice") ? "Invoice" : "AI Chat"
  );
  const { data, refetch: refetchprofile } = useQuery(["profiles"], getprofile, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      const profile_id_local = localStorage.getItem("profileid")

      // check for null or undefined profileid
      if (profile_id_local !== null && profile_id_local !== "null" && profile_id_local !== undefined && profile_id_local !== "undefined") {
        setProfileid(localStorage.getItem("profileid"));
        const profile_data = data?.data?.object?.find((profile)=>profile.id == localStorage.getItem("profileid"));
        localStorage.setItem("profile_country", profile_data?.country);

        trackEvent({
          event_type: "click", 
          event_name:"Profile Loaded", 
          event_data:{
            loaded_profile_id: `${profile_data?.id}`
          }
        })

        dispatch(setUpdateProfileEntity({...profile_data}))
      
      } else {
        if (localStorage.getItem("profileid") === null || localStorage.getItem("profileid") === undefined || localStorage.getItem("profileid") === "null" || localStorage.getItem("profileid") === "undefined"){
          // log sent right after login
          getProfileCompletionStatus(dispatch, data?.data?.object[0]?.id)
          localStorage.setItem("profileid", data?.data?.object[0]?.id);
          trackEvent({
            event_type: "click", 
            event_name:"User has logged in and Default User Profile has been Loaded", 
            event_data:{
              loaded_profile_id: `${data?.data?.object[0]?.id}`
            }
          })
          }
          
          else{
          trackEvent({
            event_type: "click", 
            event_name:"Profile was Switched", 
            event_data:{
              switch_to_profile_id: `${data?.data?.object[0]?.id}`
            }
          })          
          }

        setProfileid(data?.data?.object[0]?.id);
        localStorage.setItem("profileid", data?.data?.object[0]?.id);
        localStorage.setItem("profile_country", data?.data?.object[0]?.country);

        dispatch(setUpdateProfileEntity({...data?.data?.object[0]}))

      }
    },
  });

  function dialogContentText(){
    if (questioner_filled == true){
      return `${t("form_already_filled")}`
    }

    else if (questioner_filled == false){
      return `${t("enjoying_simpla")}`
    }
    // else if (apiCounter > 0 && questioner_filled == "false"){
    //   return `${t("unexhauted_uses_left")}`
    // }
  }

  // function disableGetFreeUsesBtn(){
  //   if (questioner_filled == "true"){
  //     return "grey"
  //   }

  //   else if (questioner_filled == false){
  //     return ""
  //   }
  //   // else if (apiCounter > 0 && questioner_filled == false){
  //   //   return "grey"
  //   // }
  // }

  function disableGetFreeUsesBtn(){
    if (questioner_filled == true){
      return "grey"
    }

    else if (questioner_filled == false){
      return ""
    }
  }

  //const [apiCounter, setApiCounter] = useState(`${useSelector((state)=>state.apiCount)}`)  
  const apiCounter = useSelector((state)=>state.apiCount)
  const signOffCredits = useSelector((state)=>state.signOffCredits)

  //console.log("uses left:",apiCounter)

  const headers = new Headers({
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json; charset=UTF-8'

  });


//   async function fetchApiCount() {
//     try {
//         const response = await fetch(`${SERVICES.apiBaseUrl}/api_usage/getApiCount`, {
//             headers,
//             method: "GET",
//         });

//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const apiCountData = await response.json();
//         //console.log("api count data =", apiCountData);

//         // Assuming setApiCount is a function to handle the API count data
//         dispatch(setApiCount(`${apiCountData?.object?.remaining_credits}`))
//                 dispatch(setCurrentPlan(`${apiCountData?.object?.plan_name}`))
//         dispatch(setCurrentPlanId(`${apiCountData?.object?.id}`))
//         dispatch(setPlanPaidStatus(`${apiCountData?.object?.paid_plan}`))
//         dispatch(setPlanSubStatus(`${apiCountData?.object?.is_subscribed}`))
//         dispatch(setPlanValidity(`${apiCountData?.object?.valid_date}`))
//         dispatch(setCurrentPlanId(`${apiCountData?.object?.id}`))
//         localStorage.setItem("apiCounter", `${apiCountData?.object?.remaining_credits}`)
//     } catch (error) {
//         //console.error("Error fetching API count:", error);
//         trackEvent({
//           event_type: "error", 
//           event_name:"Failed to fetch Api Counts (Remaining Simpla Uses)", 
//           event_data:{
//             error: `${error}`
//           }
//         })
//     }
// }


useEffect(()=>{
    if (tourState.isTourActive === true && tourState.currentStep === 10){
        setHideFeedbackBtn(false)
    }
    else if ((tourState.isTourActive === false && window.innerWidth <= 750) || window.location.href.includes("survey")) {
        setHideFeedbackBtn(true)
    }
}, [tourState])

// useEffect(()=>{
// console.log(tourState)
// }, [tourState])

  useEffect(()=>{
    fetchApiCount(dispatch, trackEvent)
    .then((res)=>{
      //console.log("Api Count Request Successful")
    }).catch((error)=>{
      //console.log("Failed to get Api Count")
    })
    getSignOffCredits(dispatch)
    questionaireStatus(dispatch, trackEvent)
    fetchSimplaEnterpriseMailStatus(trackEvent)
    if (localStorage.getItem("profileid") != null || localStorage.getItem("profileid") != undefined){
    getProfileCompletionStatus(dispatch, localStorage.getItem("profileid"))
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("onboarding") == "false") {
      setShowOnboarding(true);
      statusUpdateCall();
    }
    else{
      if ( (localStorage.getItem("onboarding") == "true" || localStorage.getItem("onboarding") == true) && (localStorage.getItem("show-disclaimer") == undefined || localStorage.getItem("show-disclaimer") == null || localStorage.getItem("show-disclaimer") == "true" || localStorage.getItem("show-disclaimer") == true)) {
      setOpen(true);
      }
    }
  }, []);


  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  const [prevTab, setPrevTab] = useState("")

  const HandleActiveTabName = (tab) =>{
    if (tab === "AI Chat") {
      return "Advise"
    } else if (tab === "Analyze") {
      return "Analyse"
    } else if (tab === "UploadInvoice" || tab === "Invoice") {
      return "Process"
    }
    else return `${tab}`
  }
  useEffect(()=>{
    //console.log("Active Tab = ", defaultTab)
    handleTabTimer(defaultTab)
  }, [defaultTab])

  const handleTabTimer = (activeTab)=>{

    if (!isRunning){
        //console.log("Timer started for", activeTab)
        start()
        setPrevTab(activeTab)
      }

      else if (isRunning && totalSeconds>0){
        pause()
        //console.log(`${HandleActiveTabName(prevTab)} Module was active for: ${totalSeconds}sec` )
        trackEvent({event_type: "time", event_name: `Time Spent in Module`, event_data: {module: `${HandleActiveTabName(prevTab)}`, time_spent: totalSeconds, unit_time: "Seconds"} })
        reset()
        setPrevTab(activeTab)

      }
  }

  // useEffect(() => {
  //   console.log("chatbot loaded")
  // }, [])

  //console.log("app loaded local")


  const makeStatusChangeCall=()=>{
    axios.post(`${SERVICES.apiBaseUrl}/user/update_disclaimer`,{
    
    },{
      headers:{
        "Authorization":`Bearer ${localStorage.getItem('token')}`
      }
    }).then((res)=>{
      
      localStorage.setItem('show-disclaimer','false');
      setOpen(false);
      
    }).catch((e)=>{
      //console.log(e)
      trackEvent({
        event_type: "error", 
        event_name:"Failed to set show disclaimer status", 
        event_data:{
          error: `${e}`
        }
      })
    })
  }



  const {
    data: chatroomsdata,
    refetch,
    isFetching,
  } = useQuery(
    [
      "chatroom",
      profileid || AccountSetting?.id,
      filter === "Favourite" ? `filter=Favourite` : ``,
      search !== "" ? `&search=${search}` : "",
    ],
    window.location.pathname.includes("invoice") && (localStorageValueTester(profileid) !== false || AccountSetting?.id)
      ? getAllBatches
      : window.location.pathname.includes("analyse") && (localStorageValueTester(profileid) !== false || AccountSetting?.id)
      ? getAnalyticsHistory
      : (localStorageValueTester(profileid) !== false || AccountSetting?.id) && getchatroom,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: profileid !== undefined,
    }
  );
  const updateStatus = useMutation(updateuserInfo);
  const statusUpdateCall = () => {
    updateStatus.mutate(
      {
        user: {
          onboarding: true,
        },
      },

      {
        onSuccess: (data, variables, context) => {
          //console.log("update: ", data)
          localStorage.setItem("onboarding", "true");
          setOpen(true)
          getProfileCompletionStatus(dispatch, localStorage.getItem("profileid"))

        //   if (localStorage.getItem("show-disclaimer") == undefined || localStorage.getItem("show-disclaimer") == null || localStorage.getItem("show-disclaimer") == "true" || localStorage.getItem("show-disclaimer") == true){
        //   console.log("opening disclaimer")  
        //   setOpen(true)}
        },
        onError: (error, variables, context) => {
          trackEvent({
            event_type: "error", 
            event_name:"Failed to update user profile data", 
            event_data:{
              error: `${error}`
            }
          })
        },
      }
    );
  };

  const isFirstRender = useRef(true);
  const historyRefetchValue = useSelector((state) => state.historyRefetch?.refetchValue);

  useEffect(()=>{

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
  }
  if (historyRefetchValue) {
    setTimeout(()=> {
      refetch();
    }, 5000)
    dispatch(setRefetchValue(false));
  }
}, [historyRefetchValue]);



  const disclaimerRef=useRef();

  const handleScroll=(e)=>{
   
   
    if(e.target.scrollTop>=500){
        setToggleActive(true);
    }
  }


  const handleFeedbackModal = () => {
    setFeedback(!feedback);
  };

  const profileSetter = (id) => {
    //console.log("swapping profile")
    if (localStorage.getItem("profileid") == null || localStorage.getItem("profileid") == undefined){
      localStorage.setItem("profileid", id);
      trackEvent({event_type:"click",event_name:"Profile Loaded", event_data: {loaded_profile_id: id} })
    }
    else{
    // used when profile was swapped
    trackEvent({event_type:"click",event_name:"profile switched", event_data: {action: "user profile was switched", new_profile_id: id} })
    }
    
    setProfileid(id);
    localStorage.setItem("profileid", id);
    if (window.location.pathname.includes("invoice")) {
      navigate("/invoice");
    } else if (window.location.pathname.includes("analyse")) {
      navigate("/analyse");
    } else {
      navigate("/");
    }
  };
  const location = useLocation();
  useEffect(() => {
    const pathnameParts = location.pathname.split("/");
    const firstPart = pathnameParts[1];
    if (
      firstPart === "chat" ||
      firstPart === "invoice" ||
      firstPart === "" ||
      firstPart === "analyse"
    ) {
      if (profileid !== undefined) {
        refetch();
      }
    }
  }, [defaultTab]);
  const { logout } = useContext(AuthContext);
  const logoutCall = () => {
    logout();
    localStorage.removeItem('show-disclaimer');
    dispatch(setApiCount("0"))
    navigate("/login");
  };
  useEffect(() => {
    if (window.location.pathname.includes("invoice")) {
      ThemeHandler("Invoice");
      setDefaultTab("Invoice");
    } else if (window.location.pathname.includes("analyse")) {
      ThemeHandler("Analyze");
      setDefaultTab("Analyze");
    } else if (window.location.pathname.includes("survey") || window.location.pathname.includes("starterplan")){
      ThemeHandler("No Tab");
      setDefaultTab("No Tab");
    } else {
      ThemeHandler("AI Chat");
      setDefaultTab("AI Chat");
    }
  }, [window.location.pathname]);

  
  const ThemeHandler = (tab) => {
  
    setTimeout(function () {
      if (tab === "No Tab"){
        setThemeclass("bg-blue-inactive-wrapper");
      }
      if (tab === "AI Chat") {
        setThemeclass("bg-blue-wrapper");
      }
      if (tab === "Invoice") {
        setThemeclass("bg-yellow-wrapper");
      }
      if (tab === "Analyze") {
        setThemeclass("bg-green-wrapper");
      }
    }, 100);
  };
  const handleTabSelect = (selectedTab) => {
  
    ThemeHandler(selectedTab);
    
    if (selectedTab === "AI Chat") {
          trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Advise"}})
          navigate("/");
    }
    if (selectedTab === "Invoice") {
      dispatch(disableAllChatMode());
      localStorage.setItem("mode", null)
      window.dispatchEvent(new Event('storage'));
      if(!window.location.href.includes("invoice"))
      trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Process"}})
    
      navigate("/invoice");
    }
    if (selectedTab === "Analyze") {
      dispatch(disableAllChatMode());
      localStorage.setItem("mode", null)
      window.dispatchEvent(new Event('storage'));
      if(!window.location.href.includes("analyse"))
      trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Analyse"}})
      
      navigate("/analyse");
    }
  };

  const navButton = useRef(null)
  const sidebarRef = useRef(null);


  const handleOutsideClick = (event) => {
    if (  sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      dispatch(setDisplaySidebar('none'))
    }
  };

  // useEffect(() => {
  //   if (displayBar === 'block') {
  //     window.addEventListener('click', handleOutsideClick);
  //   }

  //   return () => {
  //     window.removeEventListener('click', handleOutsideClick);
  //   };
  // }, []);


  return (
    <>
  <Suspense fallback={SuspenseFallback}>


  
    {/* navbar will go here */}

    {/* <Suspense fallback={<div></div>}> */}
    <AppBar position="static" style={{
      direction:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`,
      background:'#faf5e1', boxShadow:'none', paddingTop:'2%'}} 
      className="custom-navbar">
      <Toolbar>
        {/* Burger Icon for Drawer */}
        <IconButton ref={navButton} edge="start" color="black" aria-label="menu" onClick={()=> handleDisplay()}>
          <MenuIcon />
        </IconButton>

        {/* SVG Logo */}
        <div className="mobile-simpla-logo" style={{ zIndex: 200, marginLeft: "0.5%", width: "fit-content" }} onClick={() => { window.open('https://www.simpla.ai') }}>
          
          <svg cursor={'pointer'} width="123" height="28" viewBox="0 0 141 42" fill="none">
            <path d="M0.00107181 24.6167L4.81548 23.9834C5.45152 27.7435 8.13123 30.0997 12.6271 30.0997C16.4424 30.0997 19.0311 28.0612 19.0311 25.0264C19.0311 22.3989 17.1696 21.0846 14.6707 20.4048L8.90276 18.91C5.04191 17.8681 1.54412 15.7843 1.54412 10.7098C1.54412 5.36416 6.17758 1.78552 12.1265 1.78552C16.8043 1.78552 21.8463 4.05092 22.9365 9.80411L18.2585 10.5293C17.5315 7.67495 15.0338 6.18017 11.9455 6.18017C8.67526 6.18017 6.35854 7.99264 6.35854 10.4386C6.35854 12.8844 8.17571 14.0625 10.6734 14.7424L16.397 16.2372C20.3033 17.279 23.8911 19.6806 23.8911 24.8447C23.8911 30.4164 19.4397 34.4943 12.6271 34.4943C5.81451 34.4943 0.909081 31.0054 0 24.6178L0.00107181 24.6167Z" fill="#0D1E12"></path>
            <path d="M33.4319 9.71118H29.026V33.9939H33.4319V9.71118Z" fill="#0D1E12"></path>
            <path d="M31.2292 5.09281C32.6392 5.09281 33.7822 3.95269 33.7822 2.54637C33.7822 1.14004 32.6392 0 31.2292 0C29.8193 0 28.6763 1.14004 28.6763 2.54637C28.6763 3.95269 29.8193 5.09281 31.2292 5.09281Z" fill="#0D1E12"></path>
            <path d="M50.0088 13.3818C46.9649 13.3818 44.0588 15.8731 44.0588 20.9475V33.9952H39.6528V9.71247H43.6047V14.0163C44.8314 11.2526 47.5111 9.21423 51.372 9.21423C55.2328 9.21423 57.5948 11.0267 58.9572 13.7904C60.4557 11.2083 63.3173 9.21423 67.269 9.21423C72.6747 9.21423 76.2625 13.0652 76.2625 19.3166V33.9952H71.8561V20.2234C71.8561 15.9639 69.6307 13.3829 66.1328 13.3829C63.0896 13.3829 60.1825 15.4213 60.1825 20.2234V33.9952H55.7768V20.2234C55.7768 15.4657 53.006 13.3829 50.0088 13.3829V13.3818Z" fill="#0D1E12"></path>
            <path d="M93.8255 9.21423C86.3759 9.21423 81.0074 14.9512 81.0074 21.1388V41.923H85.4146V30.2782C87.4139 32.9511 90.5875 34.4923 94.4485 34.4923C101.172 34.4923 106.441 28.9186 106.441 21.8046C106.441 14.6907 101.254 9.21423 93.8255 9.21423ZM93.7223 30.369C88.9125 30.369 85.4146 26.744 85.4146 21.8046C85.4146 16.8653 88.9125 13.3829 93.7223 13.3829C98.5322 13.3829 102.036 17.0987 102.036 21.8046C102.036 26.5105 98.401 30.369 93.7223 30.369Z" fill="#0D1E12"></path>
            <path d="M115.12 0.379395H110.714V33.995H115.12V0.379395Z" fill="#0D1E12"></path>
            <path d="M140.594 33.9939V19.0927C140.594 13.293 136.006 9.21509 129.828 9.21509C124.018 9.21509 120.067 12.4327 119.522 17.4618L124.018 18.0054C124.155 14.9694 126.376 13.1115 129.828 13.1115C133.099 13.1115 135.506 15.3314 135.733 18.1859C134.552 18.548 132.735 18.9565 131.237 19.2278C125.291 20.2697 119.885 21.4478 119.885 27.4691C119.885 31.6377 123.065 34.4922 128.102 34.4922C131.782 34.4922 134.598 32.9066 136.279 29.916V33.9939H140.594ZM135.779 21.7602C135.779 26.9708 132.917 30.6412 128.692 30.6412C126.057 30.6412 124.518 29.2815 124.518 27.1978C124.518 24.5249 127.284 23.7543 131.191 23.029C133.054 22.7123 134.871 22.3492 135.779 21.7148V21.7602Z" fill="#0D1E12"></path>
          </svg>
          
        </div>

        {/* MOBILE User Profile View */}
   
          <div
              style={{
                right:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "4%"}`,
                left:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "4%" : ""}`
            }}
              className={` ${styles.profileBtnWrapper} dropdown-wrapper dropdown-iconLess`}>
           

           <Dropdown
                // style={{ right: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "88vw" : ""}` }}
                >
                <Dropdown.Toggle
                style={{background:setBGColor()}}
                  id="dropdown-basic"
                  className="p-0 profile-dropdown-mobile"
                >
                {
                  userImgAws !== "" ? 
                  <>
                    <img src={userImgAws} alt="profile_image" style={{ width: '60px', height: '60px', borderRadius: '50%', margin: '0%', background: 'white', objectFit:"contain" }} />
                  </> : 
                  <>
                    <h4 style={{margin:'1%', textAlign:'center'}}  >{firstnameAcronym}{lastnameAcronym}</h4>    
                  </>
                }                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className={styles.DropdownWrapperBox}  style={{width:"250px", overflowX:"hidden"}}>
                    
                    {data?.data?.object?.map((pr)=>{
                      var image_index=""
                      if(pr.countr=='Any Other Country'){

                      }
                      else{
                        image_index=all_countries.map((item)=> (item.label?.toLowerCase())).indexOf(`${pr.country?.toLowerCase()}`);
                      }
                    
                         
                      return(
                        <Dropdown.Item
                        onClick={() => {
                          
                          if(pr.id.toString()==localStorage.getItem('profileid')){
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            return
                          }
                          else{
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            profileSetter(pr.id);
                            window.location.reload();
                          }
                          
                          
                          // window.location.reload();
                        }}
                        className={
                          pr?.id == localStorage.getItem("profileid")
                            ? "dropdown-item selected-profile dropdown-item-custom"
                            : "dropdown-item dropdown-item-custom"
                        }
                        key={pr?.id}
                      >
                        <span className="img-wrapper">
                                 {pr.country=='Any Other Country' ? '' : <img src={`https://hatscripts.github.io/circle-flags/flags/${all_countries[image_index]?.code?.toLowerCase()}.svg`} width="48" alt=""></img>}
                          {/* {pr.country =='UAE' ? <img src={uae_logo} alt=""  /> :'' }
                          {pr.country =='KSA' ? <img src={ksa_logo} alt=""  /> :'' }
                          {pr.country =='Bahrain' ? <img src={bahrain_logo} alt='' /> :'' }
                          {pr.country =='Jordan' ? <img src={jordan_logo} alt='' /> :'' }
                          {pr.country =='Oman' ? <img src={oman_logo} alt='' /> :'' }
                          {pr.country =='Qatar' ? <img src={qatar_logo} alt='' /> :'' }
                          {pr.country =='Egypt' ? <img src={egypt_logo} alt='' /> :'' }
                          {pr.country=='Any Other Country' ? <img src={globe_logo}  /> :'' }
                          <img src={ProfileImage} /> */}
                        </span>
                        <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '137px',  // Adjust based on your layout
                              }}
                            >
                              {pr?.display_name}
                            </span>
                      </Dropdown.Item>
                      )
                    })}
                  </div>
                  <hr />
                  <h6 style={{
                        fontFamily: 'karla', marginLeft:'1%', 
                        textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`,
                        marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "3%" : ""}`

                        
                        }}> {t('option_switch_lang')} </h6>
                  {
                    languages.map((language)=>{
                        return (<>
                        <Dropdown.Item style={{
                          background: `${localStorage.getItem('i18nextLng')?.toString() === language.code ? "#f0ede4" : ""}`, 
                          width:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "100%" : "100%"}`, 
                          marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "0%"}`                        //marginLeft: `${localStorage.getItem('i18nextLng')?.toString() !==  "ar" ? "10%" : "0%"}`
                      }}
                        onClick ={(e) => {localStorage.setItem("i18nextLng", `${language.code}`); window.location.reload();
                      }}>
                        {/* <img src={`https://hatscripts.github.io/circle-flags/flags/${language.countryCode.toLowerCase()}.svg`} width="25" alt=""></img> */}
                          {language.name}
                        </Dropdown.Item>
                        </>)
                    })
                  }
                  <hr/>
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(disableAllChatMode());
                      localStorage.setItem("mode", null);
                      // Manually trigger a storage event
                      window.dispatchEvent(new Event('storage'));
                      navigate("/accountsetup");
                    }}
                  >
                    <PlusIconDark />
                    {t("option_add_acc")}
                  </Dropdown.Item>
                  <Dropdown.Item className="acc-settings" onClick={()=>{handleShow()}}>
                    <SettingIcon />
                    {t("option_edit_profile")}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); logoutCall();}}>
                    <LogoutDropdownIcon />
                    {t("option_logout")}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item style={{fontWeight:"bold"}} onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); navigate("/paymentplan")}}>
                    {t("subscription_plans")}
                  </Dropdown.Item>
                  {
                    currentPlanObj.is_subscribed === true ?
                  
                    <Dropdown.Item style={{fontWeight:"bold",}} onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); navigate("/cancelsubscription")}}>
                      {t("cancel_plan")}
                    </Dropdown.Item> : ""

                  }

                  <Dropdown.Item onClick={()=>{
                    handleMyPlanModalShow(); 
                    trackEvent({
                      event_type: "click", 
                      event_name:"User Clicked on View Current Subscription Plan", 
                      event_data:{
                        user_current_plan: `${currentPlan}`
                      }
                    })
                  }} style={{padding: "0%"}}>
                    <p style={{
                      // fontSize:'normal', 
                      fontWeight:"bold",
                      marginTop:"10px", 
                      marginLeft:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "4%"}`,
                      marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "5.5%" : "0%"}`
                  
                  }}>{t("my_current_plan")} {t(`${currentPlan.toLowerCase() === "free" || currentPlan.toLowerCase() === "questioner" ? "trial" : currentPlan}`)} </p> 
                  </Dropdown.Item>

                  <Dropdown.Item onClick={()=>{
                    handleShowUseModal();
                    trackEvent({
                      event_type: "click", 
                      event_name:"User Clicked on Remaining Uses", 
                      event_data:{
                        user_current_plan: `${currentPlan}`,
                        uses_left: `${apiCounter}`
                      }
                    })
                    }} style={{padding: "0%"}}>
                    <h6 style={{
                      fontSize:'large', 
                      marginLeft:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "4%"}`,
                      marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "5.5%" : "0%"}`
                  
                  }}>{apiCounter}</h6> <p style={{margin:"0% 0% 3% 0.5%", fontWeight:"bold", }}> {t("free_uses_left")}</p>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={()=>{

                    }} style={{padding: "0%"}}>
                    <h6 style={{
                      fontSize:'large', 
                      marginLeft:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "4%"}`,
                      marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "5.5%" : "0%"}`
                  
                  }}>{signOffCredits}</h6> <p style={{margin:"0% 0% 3% 0.5%", fontWeight:"bold", }}> {t("sign_off_credits_left")}</p>
                  </Dropdown.Item>
                  {/* <Dropdown.Item style={{padding: "0%", marginBottom: "0%"}} onClick={()=>{navigateToSurvey()}}>
                  <div className="more-uses-btn">
                      <p className="more-uses-btn-text" style={{paddingTop: "5%"}} >{t("want_more_btn")}</p>
                  </div>
                  </Dropdown.Item> */}

                </Dropdown.Menu>
              </Dropdown>

              {showAccSettings && (
                <ProfileSetting
                  handleClose={handleCloseAccSetting}
                  profiles={
                  //   data?.data?.object?.filter(
                  //   (profile) => profile.id == localStorage.getItem("profileid")
                  // )
                  [AccountSetting] // [{id: "123", display_name: "John Doe"}] => passed as an array with 1 object
                }
                  refetch={refetchprofile}
                />
              )}
          </div>

      </Toolbar>
    </AppBar>      
    {/* </Suspense> */}

    




      <div
        style={{direction:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}` }}
        className={`${styles.dashboardLayoutWrapper} ${themeclass} bg-yellow`}
      >

        <JoyrideComponent />

{/* <Dialog
  open={showModal}
  onClose={handleModalClose}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "285px",
      borderRadius: "15px",
      background: "#fdfbf5",

    }
  }}
>
  <DialogTitle>


    <div className="close-circle" 
         style={{ 
            position: 'absolute', 
            top: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "4%" : "4%"}`, 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "2.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "2.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>
                      <div style={{}}>

          <CloseIcon fontSize="normal" onClick={() => setShowModal(false)} style={{marginBottom:"15%"}} />
        </div>

      </div>
  </DialogTitle>
  <DialogContent>

    <div style={{padding:"4% 2%"}}>
    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("want_more_btn")}
    </h6>
    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"25px", paddingTop:"1%"}}>
      {dialogContentText()}
    </p>    
    </div>

  </DialogContent>
  <DialogActions style={{padding:"0% 2% 7% 2%", margin: "0% 3%", gap:"10px"}}>
  <div onClick={navigateToSurvey} className={`more-uses-btn more-uses-dialog-btn ${styles["orange-bg-btn-transition"]}`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`more-uses-btn-text ${styles["orange-bg-btn-text"]}`} style={{fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("get_more_uses")}</Typography>
    </div>

    <div className="more-uses-btn more-uses-dialog-btn" style={{ borderColor: `black`,  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }} onClick={()=>{ setShowModal(false); discoverSimplaSendEmail()}}>
      <Typography variant="p" className="more-uses-btn-text" style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("simpla_enterprise")}</Typography>
    </div>
  </DialogActions>
</Dialog> */}

{/* Subscription plan modal */}
<Dialog
  open={showMyPlanModal}
  onClose={handleMyPlanModalClose}
  fullWidth
  maxWidth="lg"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",
    }
  }}
>
  <DialogTitle 
  // style={{display:"flex", justifyContent:"flex-end", height:"60px", }}
  >
    {/* <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("want_more_btn")}
    </h6> */}

    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
        <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: `${localStorage.getItem('i18nextLng') === "ar" ? '6.5%' : '6.5%'}`, 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => handleMyPlanModalClose()} 
            style={{ margin: 0 }} 
        />
       </div>
       {/* <div 
          onClick={() => handleMyPlanModalClose()} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div> */}

      
  </DialogTitle>

  <DialogContent className="table-dialog-main">

  <div className="table-container">
  <div className={`current-plan ${currentPlanStyleClass(currentPlan)}`} > {t("current_plan")} </div>
  <table className="custom-table">
  <tbody>
    <tr>
      <td className={`column-1 ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "top-right-radius" :  "top-left-radius"}`}>{t("available_plans")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"} orange-keyword`}>{t("trial")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"} orange-keyword`}>{t("starter")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"} ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "top-left-radius" :  "top-right-radius"} orange-keyword`}>{t("enterprise")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("price")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("free")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_price")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("sales_team")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("module_usage")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("thirty_free_uses")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_uses")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("unlimited_usage")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("no_of_users")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>1</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_users")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("grouped")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("no_of_profiles")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>1</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_profiles")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("grouped")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("human_advisory")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("na")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_sign_off_credits")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("custom_plan")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("it_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("na")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_IT_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("custom_priority_support")}</td>
    </tr>
    <tr>
      <td className="column-1">{t("customer_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("na")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>{t("starter_customer_support")}</td>
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"}`}>{t("dedicated_support_manager")}</td>
    </tr>
    <tr>
      <td className={`column-1 ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "bottom-right-radius" : "bottom-left-radius"}`}></td>
     
      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>
        <div className="plan-table-custom-button" style={{fontSize: localStorage.getItem('i18nextLng')?.toString() === "ar" ? "12px" : ""}} onClick={()=>setMyPlanShowModal(false)}>{ `${currentPlanObj?.name}`?.toLowerCase() === "trial" ? t("continue_trial") : t("downgrade_to_trial")}</div>
      </td>

      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-2-ar" :  "column-2"}`}>
        <div className="plan-table-custom-button" style={{fontSize: localStorage.getItem('i18nextLng')?.toString() === "ar" ? "12px" : ""}} onClick={()=>{setMyPlanShowModal(false); navigate("/paymentplan")}}>{  `${currentPlanObj?.name}`?.toLowerCase()?.includes("starter") === true ? t("continue_starter") : t("upgrade_to_starter")}</div>
      </td>

      <td className={`${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "column-3-ar" :  "column-3"} ${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "bottom-left-radius" : "bottom-right-radius"}`}>
        <div className="plan-table-custom-button" style={{fontSize: localStorage.getItem('i18nextLng')?.toString() === "ar" ? "12px" : ""}} onClick={()=>{ setMyPlanShowModal(false);     trackEvent({event_type:"click",event_name:"Discover Simpla Enterprise Button was Clicked", event_data: {action: "user clicked 'Discover Simpla Enterprise Button'"} }); discoverSimplaSendEmail(); }}>{t("discover_enterprise")}</div>
      </td>

    </tr>

    <tr>
      <td className="" style={{opacity:0}}></td>
      <td className="" style={{opacity:0}}></td>
      <td style={{border:"none", fontSize:"10px", fontWeight:"bold" }}>{t("starter_coverage_note")}</td>
      <td className="" style={{opacity:0}}></td>

    </tr>
  </tbody>
</table>
    </div>


  </DialogContent>


</Dialog>

{/* We will be in touch modal */}
<Dialog
  open={showEnterpriseModal}
  onClose={handleCloseEnterpriseModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",


    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    {/* <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: '11.5%', 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => setShowEnterpriseModal(false)} 
            style={{ margin: 0 }} 
        />
       </div> */}
           <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowEnterpriseModal(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("in_touch_modal")}
    </h6>

    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"20px" }}>
      {t("in_touch_modal_description")}
    </p>

    </div>


  </DialogContent>


</Dialog>

{/* What is a "USE" in Simpla Modal  */}
<Dialog
  open={showUseModal}
  onClose={handleCloseUseModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",
      
    }
  }}
>
  
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    {/* <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: '4.5%', 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => setShowUseModal(false)} 
            style={{ margin: 0 }} 
        />
       </div> */}
             <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer",
            background: "#fdfbf5"
            }}>

      <div 
          onClick={() => setShowUseModal(false)} 
          style={{ cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    <h6 className="more-uses-heading" style={{ paddingTop:"3%", fontWeight: "bold", fontFamily: "karla", textAlign: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "right" : "left"}` }}>
      {t("use_modal_title")}
    </h6>

    <div style={{padding: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 0% 0% 0%" : "1% 0% 0% 0%" }`}}>
      <p style={{ fontFamily: 'karla', fontWeight: 500, lineHeight:"20px", textAlign: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "right" : "left"}` }}>
          {t("use_modal_content_p1")}
        </p>  

        <p style={{ paddingTop:"2%", fontFamily: 'karla', lineHeight:"20px", fontWeight: 500, }}>
          {t("use_modal_content_p2")}
        </p>  

        <div className="module-use-info-div" style={{ paddingTop:"3%", lineHeight:"20px", fontFamily: 'karla', fontWeight: 500, }}>
        <AdviseModuleIcon/> {" "} <b className="module-use-info"  style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 1% 0% 0%" : "1% 0% 0% 1%"}`}}>{t("advise_module")}</b> <p style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 4% 0% 0%" : "1% 0% 0% 2%"}`}}>{t("advise_use")}</p>
        </div>  

        <div className="module-use-info-div" style={{ paddingTop:"2%", lineHeight:"20px",  fontFamily: 'karla', fontWeight: 500, }}>
        <ProcessModuleIcon/> {" "} <b className="module-use-info" style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 1% 0% 0%" : "1% 0% 0% 1%"}`}}>{t("process_module")}</b> <p style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 4% 0% 0%" : "1% 0% 0% 2%"}`}}>{t("process_use")}</p>
        </div>  

        <div className="module-use-info-div"  style={{ paddingTop:"2%", lineHeight:"20px", fontFamily: 'karla', fontWeight: 500, }}>
        <AnalyseModuleIcon/> {" "} <b className="module-use-info" style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 1% 0% 0%" : "1% 0% 0% 1%"}`}}>{t("analyse_module")}</b> <p style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 4% 0% 0%" : "1% 0% 0% 2%"}`}}>{t("analyse_use")}</p>
        </div>  

        <p style={{ paddingTop:"3%", fontFamily: 'karla', fontSize:"large", lineHeight:"20px", paddingRight:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "normal" : "bold"}`}}>
          {t("use_modal_content_p3")}
        </p>  
    </div>


    </div>

  </DialogContent>

  <DialogActions style={{padding:"0% 3.5% 7% 3.5%", margin: "0% 3%", gap:"10px"}} >
    <div onClick={()=> {setShowUseModal(false); navigateToSurvey()}} className={`more-uses-btn more-uses-dialog-btn ${styles["orange-bg-btn-transition"]}`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`more-uses-btn-text ${styles["orange-bg-btn-text"]}`} style={{fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("get_more_uses")}</Typography>
    </div>

    <div className="more-uses-btn more-uses-dialog-btn" style={{ borderColor: `black`,  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }} onClick={()=>{ setShowUseModal(false); trackEvent({event_type:"click",event_name:"Discover Simpla Enterprise Button was Clicked", event_data: {action: "user clicked 'Discover Simpla Enterprise Button'"} }); discoverSimplaSendEmail()}}>
      <Typography variant="p" className="more-uses-btn-text " style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("discover_enterprise")}</Typography>
    </div>
  </DialogActions>


</Dialog>

   
        <div className={`${styles.sidebarWrapper}`}>
        
          {/* <div className="overlay">
          <Spinner animation="border" variant="primary" />
        </div> */}
          <Sidebar
            style={{position:"fixed"}}
            ref={sidebarRef}
            profiles={data?.data?.object}
            profileSetter={profileSetter}
            profileid={profileid}
            chatroomsdata={chatroomsdata?.data?.object}
            styleClass="bg-color"
            refetch={refetch}
            loading={isFetching}
            filterSetter={filterSetter}
            filter={filter}
            searchSetter={searchSetter}
            search={search}
            handleCloseOnboarding={handleCloseOnboarding}
          />


          
        </div>


           

         
        <div className={`${styles.rightArea} ${styles.rightSideWrapper}`} >

        <div className={`main-simpla-logo ${ localStorage.getItem("i18nextLng") === "ar" ? "chatbot-layout-margin-ar" : "chatbot-layout-margin-en"}`} style={{ zIndex:50, width:'fit-content', height:'fit-content', paddingLeft:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "2%"}`, paddingRight:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "2%" : ""}`}} onClick={() => { window.open('https://www.simpla.ai') }}>
<a target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>

  <svg className="simpla-logo" style={{paddingLeft:'5%'}} cursor={'pointer'} width="130" height="35" viewBox="0 0 141 42" fill="none">
  <path d="M0.00107181 24.6167L4.81548 23.9834C5.45152 27.7435 8.13123 30.0997 12.6271 30.0997C16.4424 30.0997 19.0311 28.0612 19.0311 25.0264C19.0311 22.3989 17.1696 21.0846 14.6707 20.4048L8.90276 18.91C5.04191 17.8681 1.54412 15.7843 1.54412 10.7098C1.54412 5.36416 6.17758 1.78552 12.1265 1.78552C16.8043 1.78552 21.8463 4.05092 22.9365 9.80411L18.2585 10.5293C17.5315 7.67495 15.0338 6.18017 11.9455 6.18017C8.67526 6.18017 6.35854 7.99264 6.35854 10.4386C6.35854 12.8844 8.17571 14.0625 10.6734 14.7424L16.397 16.2372C20.3033 17.279 23.8911 19.6806 23.8911 24.8447C23.8911 30.4164 19.4397 34.4943 12.6271 34.4943C5.81451 34.4943 0.909081 31.0054 0 24.6178L0.00107181 24.6167Z" fill="#0D1E12"></path>
  <path d="M33.4319 9.71118H29.026V33.9939H33.4319V9.71118Z" fill="#0D1E12"></path>
  <path d="M31.2292 5.09281C32.6392 5.09281 33.7822 3.95269 33.7822 2.54637C33.7822 1.14004 32.6392 0 31.2292 0C29.8193 0 28.6763 1.14004 28.6763 2.54637C28.6763 3.95269 29.8193 5.09281 31.2292 5.09281Z" fill="#0D1E12"></path>
  <path d="M50.0088 13.3818C46.9649 13.3818 44.0588 15.8731 44.0588 20.9475V33.9952H39.6528V9.71247H43.6047V14.0163C44.8314 11.2526 47.5111 9.21423 51.372 9.21423C55.2328 9.21423 57.5948 11.0267 58.9572 13.7904C60.4557 11.2083 63.3173 9.21423 67.269 9.21423C72.6747 9.21423 76.2625 13.0652 76.2625 19.3166V33.9952H71.8561V20.2234C71.8561 15.9639 69.6307 13.3829 66.1328 13.3829C63.0896 13.3829 60.1825 15.4213 60.1825 20.2234V33.9952H55.7768V20.2234C55.7768 15.4657 53.006 13.3829 50.0088 13.3829V13.3818Z" fill="#0D1E12"></path>
  <path d="M93.8255 9.21423C86.3759 9.21423 81.0074 14.9512 81.0074 21.1388V41.923H85.4146V30.2782C87.4139 32.9511 90.5875 34.4923 94.4485 34.4923C101.172 34.4923 106.441 28.9186 106.441 21.8046C106.441 14.6907 101.254 9.21423 93.8255 9.21423ZM93.7223 30.369C88.9125 30.369 85.4146 26.744 85.4146 21.8046C85.4146 16.8653 88.9125 13.3829 93.7223 13.3829C98.5322 13.3829 102.036 17.0987 102.036 21.8046C102.036 26.5105 98.401 30.369 93.7223 30.369Z" fill="#0D1E12"></path>
  <path d="M115.12 0.379395H110.714V33.995H115.12V0.379395Z" fill="#0D1E12"></path>
  <path d="M140.594 33.9939V19.0927C140.594 13.293 136.006 9.21509 129.828 9.21509C124.018 9.21509 120.067 12.4327 119.522 17.4618L124.018 18.0054C124.155 14.9694 126.376 13.1115 129.828 13.1115C133.099 13.1115 135.506 15.3314 135.733 18.1859C134.552 18.548 132.735 18.9565 131.237 19.2278C125.291 20.2697 119.885 21.4478 119.885 27.4691C119.885 31.6377 123.065 34.4922 128.102 34.4922C131.782 34.4922 134.598 32.9066 136.279 29.916V33.9939H140.594ZM135.779 21.7602C135.779 26.9708 132.917 30.6412 128.692 30.6412C126.057 30.6412 124.518 29.2815 124.518 27.1978C124.518 24.5249 127.284 23.7543 131.191 23.029C133.054 22.7123 134.871 22.3492 135.779 21.7148V21.7602Z" fill="#0D1E12"></path>
</svg>
</a>
</div>
          <main className={`${ localStorage.getItem("i18nextLng") === "ar" ? "chatbot-layout-margin-ar" : "chatbot-layout-margin-en"} ${styles.mainWrapper} ${window.location.href.includes("survey") ? styles.mainWrapperInactiveTab : ""}`}  style={{overflow: `${ ( window.location.href.includes("invoice") || window.location.href.includes("analyse")  ) ? "auto" : ""}`,}}>

            {/* desktop user profile  */}
          <div
              style={{
                right:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "47px"}`,
                left:  `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "47px" : ""}`
            }}
              className={`desktop-profile-view ${styles.profileBtnWrapper} dropdown-wrapper dropdown-iconLess`}>

              <Dropdown
                // style={{ right: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "88vw" : ""}` }}
                >
                <Dropdown.Toggle
                style={{background:setBGColor()}}
                  id="dropdown-basic"
                  className="p-0 profile-dropdown"
                >
                {
                  userImgAws !== "" ? 
                  <>
                    <img src={userImgAws} alt="profile_image" style={{ width: '60px', height: '60px', borderRadius: '50%', margin: '0%', background: 'white', objectFit:"contain" }} />
                  </> : 
                  <>
                    <h4 style={{margin:'1%', textAlign:'center'}}  >{firstnameAcronym}{lastnameAcronym}</h4>    
                  </>
                }                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className={styles.DropdownWrapperBox}  style={{width:"250px", overflowX:"hidden"}}>
                    
                    {data?.data?.object?.map((pr)=>{
                      var image_index = ""
                      if(pr.countr=='Any Other Country'){

                      }
                      else{
                        image_index=all_countries.map((item)=> (item.label?.toLowerCase())).indexOf(`${pr.country?.toLowerCase()}`);
                      }
                      

                         
                      return(
                        <Dropdown.Item
                        onClick={() => {
                          
                          if(pr.id.toString()==localStorage.getItem('profileid')){
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            return
                          }
                          else{
                            localStorage.setItem("profile_country", `${pr?.country}`)
                            profileSetter(pr.id);
                            window.location.reload();
                          }
                          
                          
                          // window.location.reload();
                        }}
                        className={
                          pr?.id == localStorage.getItem("profileid")
                            ? "dropdown-item selected-profile"
                            : "dropdown-item"
                        }
                        key={pr?.id}
                      >
                        <span className="img-wrapper">
                                 {pr.country=='Any Other Country' ? '' : <img src={`https://hatscripts.github.io/circle-flags/flags/${all_countries[image_index]?.code?.toLowerCase()}.svg`} width="48" alt=""></img>}
                          {/* {pr.country =='UAE' ? <img src={uae_logo} alt=""  /> :'' }
                          {pr.country =='KSA' ? <img src={ksa_logo} alt=""  /> :'' }
                          {pr.country =='Bahrain' ? <img src={bahrain_logo} alt='' /> :'' }
                          {pr.country =='Jordan' ? <img src={jordan_logo} alt='' /> :'' }
                          {pr.country =='Oman' ? <img src={oman_logo} alt='' /> :'' }
                          {pr.country =='Qatar' ? <img src={qatar_logo} alt='' /> :'' }
                          {pr.country =='Egypt' ? <img src={egypt_logo} alt='' /> :'' }
                          {pr.country=='Any Other Country' ? <img src={globe_logo}  /> :'' }
                          <img src={ProfileImage} /> */}
                        </span>
                        <span
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '150px',  // Adjust based on your layout
                              }}
                            >
                              {pr?.display_name}
                            </span>
                      </Dropdown.Item>
                      )
                    })}
                  </div>
                  <hr />
                  <h6 style={{
                        fontFamily: 'karla', marginLeft:'1%', 
                        textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`,
                        marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "3%" : ""}`

                        
                        }}> {t('option_switch_lang')} </h6>
                  {
                    languages.map((language)=>{
                        return (<>
                        <Dropdown.Item style={{
                          background: `${localStorage.getItem('i18nextLng')?.toString() === language.code ? "#f0ede4" : ""}`, 
                          width:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "100%" : "100%"}`, 
                          marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "0%"}`                        //marginLeft: `${localStorage.getItem('i18nextLng')?.toString() !==  "ar" ? "10%" : "0%"}`
                      }}
                        onClick ={(e) => {localStorage.setItem("i18nextLng", `${language.code}`); window.location.reload();
                      }}>
                        {/* <img src={`https://hatscripts.github.io/circle-flags/flags/${language.countryCode.toLowerCase()}.svg`} width="25" alt=""></img> */}
                          {language.name}
                        </Dropdown.Item>
                        </>)
                    })
                  }
                  <hr/>
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(disableAllChatMode());
                      localStorage.setItem("mode", null);
                      // Manually trigger a storage event
                      window.dispatchEvent(new Event('storage'));
                      navigate("/accountsetup");
                    }}
                  >
                    <PlusIconDark />
                    {t("option_add_acc")}
                  </Dropdown.Item>
                  <Dropdown.Item className="acc-settings" onClick={()=>{handleShow()}}>
                    <SettingIcon />
                    {t("option_edit_profile")}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); logoutCall();}}>
                    <LogoutDropdownIcon />
                    {t("option_logout")}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item style={{fontWeight:"bold"}} onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); navigate("/paymentplan")}}>
                    {t("subscription_plans")}
                  </Dropdown.Item>
                  
                  {
                    currentPlanObj.is_subscribed === true ?
                  
                    <Dropdown.Item style={{fontWeight:"bold",}} onClick={()=>{dispatch(disableAllChatMode()); localStorage.setItem("mode", null); window.dispatchEvent(new Event('storage')); navigate("/cancelsubscription")}}>
                      {t("cancel_plan")}
                    </Dropdown.Item> : ""

                  }


                  <Dropdown.Item onClick={()=>{
                    handleMyPlanModalShow(); 
                    trackEvent({
                      event_type: "click", 
                      event_name:"User Clicked on View Current Subscription Plan", 
                      event_data:{
                        user_current_plan: `${currentPlan}`
                      }
                    })
                  }} style={{padding: "0%"}}>
                    <p style={{
                      // fontSize:'normal', 
                      fontWeight:"bold",
                      marginTop:"10px", 
                      marginLeft:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "4%"}`,
                      marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "5.5%" : "0%"}`
                  
                  }}>{ currentPlanObj.is_subscribed === "true" || currentPlanObj.is_subscribed === true  ? t("my_current_plan") : `${currentPlan.includes("-") ? currentPlan.split("-")[0] : currentPlan} ${t("plan")}:`} {t(`${currentPlan.toLowerCase() === "free" || currentPlan.toLowerCase() === "questioner" ? "trial" : `${ currentPlanObj.is_subscribed === "true" || currentPlanObj.is_subscribed === true ? currentPlan : `${t("valid_until")} ${currentPlanObj.valid_date}` }`}`)} </p> 
                  </Dropdown.Item>
                  <Dropdown.Item onClick={()=>{
                    handleShowUseModal();
                    trackEvent({
                      event_type: "click", 
                      event_name:"User Clicked on Remaining Uses", 
                      event_data:{
                        user_current_plan: `${currentPlan}`,
                        uses_left: `${apiCounter}`
                      }
                    })
                    }} style={{padding: "0%"}}>
                    <h6 style={{
                      fontSize:'large', 
                      marginLeft:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "4%"}`,
                      marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "5.5%" : "0%"}`
                  
                  }}>{apiCounter}</h6> <p style={{margin:"0% 0% 3% 0.5%", fontWeight:"bold", }}> {t("free_uses_left")}</p>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={()=>{

                    }} style={{padding: "0%"}}>
                    <h6 style={{
                      fontSize:'large', 
                      marginLeft:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "0%" : "4%"}`,
                      marginRight: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "5.5%" : "0%"}`

                    }}>{signOffCredits}</h6> <p style={{margin:"0% 0% 3% 0.5%", fontWeight:"bold", }}> {t("sign_off_credits_left")}</p>
                    </Dropdown.Item>

                  {/* <Dropdown.Item style={{padding: "0%", marginBottom: "0%"}} onClick={()=>{navigateToSurvey()}}>
                  <div className="more-uses-btn">
                      <p className="more-uses-btn-text" style={{paddingTop: "5%"}} >{t("want_more_btn")}</p>
                  </div>
                  </Dropdown.Item> */}

                </Dropdown.Menu>
              </Dropdown>

              {show && (
                <ProfileSetting
                  handleClose={handleClose}
                  profiles={
                  //   data?.data?.object?.filter(
                  //   (profile) => profile.id == localStorage.getItem("profileid")
                  // )
                  [AccountSetting] // [{id: "123", display_name: "John Doe"}] => passed as an array with 1 object
                }
                  refetch={refetchprofile}
                />
              )}
          </div>

          {/* top tabs to navigate to process/advise/invoice */}
          
            <Tabs
              style={{height:"75px", background:"white"}}
              defaultActiveKey={defaultTab}
              id="uncontrolled-tab-example"
              className="mb-3 Tab-control-wrapper"
              onSelect={handleTabSelect}
              activeKey={defaultTab}
            >              
              
              <Tab
                
                eventKey="AI Chat"
                title={
                  <>
                
                    <span className="advise-module-target" >
                      {" "}
                      <CommentIcon />{" "}
                    </span>
                    
                    <div className={`${styles.chatToolTip}`}>{t("advise")}</div>
                  </>
                }
                selected
              >
                {tourState.currentStep < 4 && <Outlet />}
              </Tab>

              <Tab
                eventKey="Invoice"
                title={
                  <>
                    <span>
                      {" "}
                      <UploadFileIcon />
                    </span>
                    <div className={`${styles.uploadToolTip}`}>{t("process")}</div>
                  </>
                }
                className="Upload"
              >
                <div style={{padding: "0vw 1vw"}}>
                {!tourState.isTourActive && <Outlet />}
                </div>
              </Tab>

              <Tab
                eventKey="Analyze"
                title={
                  <>
                    <span>
                      {" "}
                      <TablerIcon />{" "}
                    </span>
                    <div className={`${styles.analyzeToolTip}`}>{t("analyse")}</div>
                  </>
                }
                className="tabler"
              >
                <div style={{padding: "0vw 1vw"}}>
                    {!tourState.isTourActive && <Outlet />}
                </div>
              </Tab>

            </Tabs>            
    

            {!tourState.isTourActive || tourState.currentStep <=3 ? 
                    <div 
                        style={{padding: `${window.location.href.includes('analyse') || window.location.href.includes('invoice') ? "0px 2%" : ""}`}}>
                            {children}
                    </div> : ""
            }


            {tourState.currentStep === 4 && <InvoiceScreen />}
            {tourState.currentStep === 5 && <DataResultsOnboarding />}
            {tourState.currentStep === 6 && <DataResultsOnboarding />}
            {tourState.currentStep === 7 && <Analyze />}
            {tourState.currentStep > 7 && <GraphOnboardingResult />}
            
            <ButtonRounded
              hide_btn={window.location.href.includes('/survey') === true ? true : hideFeedbackBtn}
              buttonIcon={feedback ? <CrossIcon /> : <FeedbackIcon />}
              styleclass={`feedback-btn btn-rounded-sm ${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "arabic-locale-placement" : "normal-locale-placement"}`}
              btnBorder="none"
              onClick={handleFeedbackModal}
            />
            {feedback ? <FeedbackModal /> : null}

          </main>

          
          
        </div>
      </div>
      <Dialog open={open}   sx={{"& .MuiPaper-root":{borderRadius:'16px',background:'#faf5e1',direction: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "rtl" : "ltr"}`,minWidth:{xs:'94vw',md:'850px'},minHeight:'550px'}}} >
    <Box sx={{marginTop:'1.5%', display:'flex',justifyContent:'center'}} >
                    <h4 variant='body1' style={{fontWeight:700,textAlign:'center'}} >  {t("disclaimer_for_simpla")}
                    </h4>
                   
                </Box>
                
              <DialogContent sx={{maxHeight:'400px', direction: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`}} onScroll={handleScroll} ref={disclaimerRef}   >
              
                <Box mt={3} className="disclaimer-box" >
                      <Typography variant="body2" sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > 
                      {t("disclaimer_body")}
                      </Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_advise")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >{t("disclaimer_advise_body")}
</Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_analyse")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >
                      {t("disclaimer_analyse_body")}
                    </Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("disclaimer_process")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  >{t("disclaimer_process_body")}</Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textDecoration:'underline',textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} >{t("disclaimer_general")} </Typography>
                    <Typography variant='body2' sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}}  > {t("disclaimer_general_body")}

</Typography>
                </Box>
                <Box mt={3} className="disclaimer-box" >
                    <Typography sx={{textAlign: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "right" : "left"}`}} > {t("help_phrase")} <a> {t("help_email")} </a> </Typography>
              
                </Box>
              </DialogContent>
              <Box className='disclaimer-checkbox-and-button'  mt={3} sx={{  display:'flex',gap:'0.5rem',width:'100%',alignItems:'center'}} >
                <Box  sx={{display:'flex',gap:'0.1rem',alignItems:'center', fontWeight:500}} >
                <Checkbox
                    disabled={toggleActive===true ? false :true}
                    sx={{color:'#FF7420','& .MuiSvgIcon-root':{color:'#FF7420'}}}
                      checked={isChecked===true ? true :false}
                      onClick={()=>setIsChecked(!isChecked)}
                    />
                    
                    {t("skip_disclaimer")}                   
                </Box>
                <MuiButton className="disclaimer-agree-button" disabled={toggleActive===false ? true :false} disableElevation disableFocusRipple disableRipple disableTouchRipple variant='contained' sx={{  letterSpacing:'-0.645888px !important', fontSize:'20px', "&:disabled":{background:'white !important',color:'black !important'}, borderRadius:'18px', fontFamily: 'Karla, sans-serif', width:'210px',height:'40px', textTransform:'none', background:toggleActive==false ? 'white' :'#FF7420', color:toggleActive==false ? 'black' :'white','&:hover':{background:toggleActive==false ? 'white' :'#FF7420'}}}   onClick={(e) => {
                if(isChecked===true){
                      makeStatusChangeCall();
                }
                else{
                  localStorage.setItem('show-disclaimer','false');
                  setOpen(false);
                }
              
              
              
           
              
              }} >
                    {t("understand")}
                </MuiButton>
              {/* <PrimaryButton
              buttonText={
               'I understand'
              }
              
              styleclass={`${toggleActive === false  ? 'primarybtn btn-common-primary mt-auto disclaimerbtn' :''}`}
              icon={undefined}
              
              type={"submit"}
              hasIcon={false}
              size={undefined}
              disabled={toggleActive==false ? true :false}
              onClick={(e) => {
                if(isChecked===true){
                      makeStatusChangeCall();
                }
                else{
                  localStorage.setItem('show-disclaimer','false');
                  setOpen(false);
                }
              
              
              
           
              
              }}
            /> */}
              </Box>
    </Dialog>

          {/* profile completed success modal */}
<Dialog
  open={showProfileCompleteModal}
  onClose={handleCloseProfileCompleteModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "285px",
      borderRadius: "20px",
      background: "#fdfbf5",

    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    {/* <div className="close-circle" 
         style={{ 
            position: 'absolute', 
            top: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "3.5%" : "3.5%"}`, 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "2%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "2%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>
                      <div style={{}}>

          <CloseIcon onClick={() => setShowProfileCompleteModal(false)} style={{fontSize:"18px", marginBottom:"20%"}} />
        </div>

      </div> */}
      <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowProfileCompleteModal(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>
  <DialogContent>

    <div style={{padding:"4% 2% 0% 2%"}}>
    <h6 className="more-uses-heading sign-off-modal-h" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("profile_complete_heading")}
    </h6>
    <p className="sign-off-modal-p" style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"25px", paddingTop:"1%"}}>
      {t("profile_complete_body1")} <br/> {t("profile_complete_body2")}

    </p>    
    </div>

  </DialogContent>
  <DialogActions style={{padding:"0% 2% 7% 2%", margin: "4% 3% 0% 3%", gap:"10px"}}>
  {/* <div onClick={()=>{setShowProfileStatusModal(false); }} className={`more-uses-btn more-uses-dialog-btn ${styles["orange-bg-btn-transition"]}`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`upgrade-plan-modal-button-text ${styles["orange-bg-btn-text"]}`} style={{color: "#ff5b00", fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("return_to_simpla")}</Typography>
    </div> */}

    <div className="sign-off-modal-btn-black more-uses-dialog-btn profile-complete-modal-btn" style={{ width: "250px", background:"#dfdffd", border: "2px solid #9696fa" }} onClick={()=>{ dispatch(disableAllChatMode()); handleCloseProfileCompleteModal(); navigate("/")  }}>
      <Typography variant="p" className="upgrade-plan-modal-button-text" style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("profile_complete_button_text")}</Typography>
    </div>

  </DialogActions>
</Dialog>

    </Suspense>
    </>
  );
};

export default ChatBotLayout;

function localStorageValueTester(val){
    if (val !== null && val !== "null" && val !== undefined && val !== "undefined"){
        return true;
    }
    else{
        return false
    }
}

function filterEmptyStringProps(obj) {
    // Iterate over the object's properties
    for (const [key, value] of Object.entries(obj)) {
      // Check if the value is an empty string or contains only whitespace
      if (typeof value === "string" && value.trim() === "") {
        // Remove the property from the object
        delete obj[key];
      }
    }
    
    return obj;
  }

export {localStorageValueTester, filterEmptyStringProps}

